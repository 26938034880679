import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch } from '@fortawesome/fontawesome-free-solid/';
import { Scrollbars } from 'react-custom-scrollbars';
import { NavContext } from '../../ContextProviders/NavProvider';
import { CollectionListContext } from '../../ContextProviders/CollectionListProvider';
import '../../assets/css/animation.css';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import { faShareAlt, faCheckCircle } from '@fortawesome/fontawesome-free-solid/';
import Loading from '../Helpers/Loading';

import PublicCollection from './PublicCollection';

// The following styles are everything above the search bar.
const ClosePaneWrapper = styled.div`
	display: block;
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: rgb(178 183 190);
	&:hover {
		color: #1a56eb;
	}
`;

const CollectionsContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 30px 50px 25px;
	padding: 15px 0 10px;
	padding-top: 35px;
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 25px - 30px);
	overflow: hidden;
`;

const TitleContainer = styled.div`
	display: flex;
	color: #6a7583;
	font-size: 14px;
`;

const LinkContainer = styled.div`
	display: flex;
	color: #6a7583;
	font-size: 14px;
	margin-right: 5px;
	&:hover {
		color: #1a56eb;
	}
`;
const CreateCnWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 45px;
	margin-bottom: 15px;
`;

const CollectionsText = styled.h3`
	font-size: 18px;
	cursor: grab;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	touch-action: manipulation;
	margin: 0;
	padding: 0;
	color: #6d7886;
	width: 60%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 23px;
	padding-bottom: 10px;
`;
const CreateCnBtn = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px 5px;
	line-height: 10px;
	width: 40%;
	font-weight: 700;
	font-size: 15px;
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	border-radius: 10px;
	cursor: pointer;
	max-width: 155px;
	height: 80%;
	&:hover {
		background-color: #324599;
		color: rgb(255, 255, 255);
	}
	&:focus {
		outline: none;
	}
`;

const SearchBarContainer = styled.div`
	position: relative;
`;

const SearchInput = styled.input`
	padding-right: 30px;
	padding: 8px;
	color: #6a7583;
	border: 1px solid #ebebeb;
	background-color: #ffffff;
	width: 100%;
`;

const SearchIconWrapper = styled.div`
	color: #7d8085;
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 17px;
	border: none;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	&:hover {
		color: #1a56eb;
	}
`;

// The following styles are everything under the search bar.
const CnDisplayContainer = styled.div`
	// background-color: purple;
	// border: 1px solid red;
	margin-top: 60px;
	margin-bottom: 30px;
	position: relative;
	padding-bottom: 13px;
	height: 93%;
	min-width: 315px;
`;

const PaddingLeftNRightWrapper = styled.div`
	// background-color: yellow;
	padding-top: 30px;
	padding-left: 15px;
	padding-right: 15px;
`;

const CnTitleNDescContainer = styled.ul`
	// background-color: orange;
	// border 1px solid blue;
	padding-left: 0px;
	margin-bottom: 60px;
	list-style: none;
`;

const CnTitleNDescMap = styled.div`
	margin-bottom: 13px;
	cursor: pointer;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 18px;
	line-height: 26px;
`;

const CnTitle = styled.span`
	color: #6a7583;
	font-size: 14px;
	&:hover {
		background-color: #ececec;
	}
`;

const CnDescription = styled.p`
	font-style: italic;
	font-size: 14px;
`;

const FiltersContainer = styled.div`
	display: flex;
`;

const DateSpan = styled.span`
	font-size: 12px;
	margin-right: 5px;
`;
const ViewButtonsContainer = styled.div`
	display: flex;
	position: relative;
`;
const ViewButtonDivider = styled.p`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px 5px;
	line-height: 10px;
	font-weight: 700;
	font-size: 15px;
	color: #324599;
	background-color: #fff;
`;
const ViewButton = styled.div`
	font-size: 18px;
	cursor: grab;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	margin: 0 2.5px 0 2.5px;
	padding: 0;
	color: #6d7886;
	white-space: nowrap;
	cursor: pointer;
`;
const CopyOverlay = styled.div`
	width: 90%;
	color: rgb(14, 173, 58);
	position: absolute;
	z-index: 5000;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	align-items: flex-end;
	height: 75%;
	top: 0;
`;

const HideCheckboxContainer = styled.div`
	display: flex;
	align-items: center;
	margin-left: 15px;
`;

const CollectionsList = props => {
	// Local State
	const [text, setText] = useState('');

	const [currentPage, setCurrentPage] = useState(0);
	const [data, setData] = useState([]);
	const [tags, setTags] = useState([]);
	const [categories, setCategories] = useState([]);
	const [baseFilter, setBaseFilter] = useState({ label: 'Modified Newest-Oldest', value: 'Modified Newest-Oldest' });
	const [tagFilter, setTagFilter] = useState(null);
	const [authorFilter, setAuthorFilter] = useState(null);
	const [categoryFilter, setCategoryFilter] = useState(null);
	const [view, setView] = useState('mine');
	const [affiliateCode, setAffiliateCode] = useState('');
	const [copyOverlay, setCopyOverlay] = useState(false);
	const [loading, setLoading] = useState(false);
	const [seenList, setSeenList] = useState([]);
	const [publicAuthors, setPublicAuthors] = useState([]);
	const [hideSeen, setHideSeen] = useState(false);
	const [hideMine, setHideMine] = useState(false);

	// Global State
	const { collectionState, collectionDispatch, fetchUserCollectionList, searchCollectionList, fetchPublicCollections, searchPublicCollections } =
		useContext(CollectionListContext);
	const { handleAddCollection, createCollection, handleAddSharedCollection, dispatch } = useContext(NavContext);

	const { isProUser, authState } = useContext(AuthContext);

	useEffect(() => {
		const tagId = tagFilter ? tagFilter.value : null;
		const categoryId = categoryFilter ? categoryFilter.id : null;
		fetchUserCollectionList({ uniqueKey: props.uniqueKey, userId: props.user.id, shared: false });
		affiliateCheck();
		// const socket = io(process.env.REACT_APP_BASE_URL, { transports: ['websocket'] });
		// setSocket(io(process.env.REACT_APP_BASE_URL, { transports: ['websocket'] }))
		// socket.on('get_updated_cn_list', (msg) => {
		// 	fetchUserCollectionList({ uniqueKey: props.uniqueKey, userId: props.user.id });
		// });

		// return () => socket.off('get_updated_cn_list');
	}, []);

	useEffect(() => {
		const tagId = tagFilter ? tagFilter.value : null;
		const categoryId = categoryFilter ? categoryFilter.id : null;
		if (authState && authState.user && authState.user.id) {
			const socket = props.socket;

			socket.on('get_updated_cn_list_' + authState.user.id, msg => {
				console.log('socket view', view);
				if (view !== 'public') {
					fetchUserCollectionList({ uniqueKey: props.uniqueKey, userId: props.user.id, shared: view === 'mine' ? false : true });
				} else {
					fetchSeenList();
					fetchPublicCollections({ uniqueKey: props.uniqueKey, userId: props.user.id });
				}
			});

			return () => socket.off('get_updated_cn_list_' + authState.user.id);
		}
	}, [authState, view]);
	// console.log('view',view)
	const unique = () => {
		return new Date().getTime().toString(36);
	};
	useEffect(() => {
		if (view === 'mine') {
			fetchUserCollectionList({ uniqueKey: props.uniqueKey, userId: props.user.id, shared: false });
		}
		if (view === 'shared') {
			fetchUserCollectionList({ uniqueKey: props.uniqueKey, userId: props.user.id, shared: true });
		}
		if (view === 'public') {
			fetchPublicAuthors();
			fetchSeenList();
			fetchPublicCollections({ uniqueKey: props.uniqueKey, userId: props.user.id });
			// fetchPublicTags()
		}
	}, [view]);
	useEffect(() => {
		if (collectionState[props.uniqueKey] && collectionState[props.uniqueKey].collections) {
			if (props.hideDone)
				collectionsSort(
					collectionState[props.uniqueKey].collections.filter((e, i) => {
						if (props.hideMine) return !seenList.some(el => e.id === el.collection_id) && e.name !== props.user.name;
						else return !seenList.some(el => e.id === el.collection_id);
					})
				);
			else {
				props.hideMine
					? collectionsSort(collectionState[props.uniqueKey].collections.filter(e => e.name !== props.user.name))
					: collectionsSort(collectionState[props.uniqueKey].collections);
			}
		}
	}, [props.hideMine]);
	useEffect(() => {
		if (collectionState[props.uniqueKey] && collectionState[props.uniqueKey].collections) {
			if (props.hideMine)
				collectionsSort(
					collectionState[props.uniqueKey].collections.filter((e, i) => {
						if (props.hideDone) return !seenList.some(el => e.id === el.collection_id) && e.name !== props.user.name;
						else return e.name !== props.user.name;
					})
				);
			else {
				props.hideDone
					? collectionsSort(collectionState[props.uniqueKey].collections.filter(e => !seenList.some(el => e.id === el.collection_id)))
					: collectionsSort(collectionState[props.uniqueKey].collections);
			}
		}
	}, [props.hideDone]);
	useEffect(() => {
		if (collectionState[props.uniqueKey]) {
			setText(collectionState[props.uniqueKey].text);
			setLoading(false);
		}
		if (collectionState[props.uniqueKey] && collectionState[props.uniqueKey].collections) {
			if (view === 'public') {
				collectionsSort(publicCollectionsFilter(collectionState[props.uniqueKey].collections));
			} else {
				collectionsSort(collectionState[props.uniqueKey].collections);
				setLoading(false);
			}
		}
	}, [collectionState[props.uniqueKey]]);
	const fetchSeenList = async () => {
		const seenList = await fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/getSeenPublicCollections/${authState.user.id}`)
			.then(res => res.json())
			.then(publicCollections => {
				return publicCollections;
			});
		if (props.hideDone) {
			collectionsSort(collectionState[props.uniqueKey].collections.filter((e, i) => !seenList.some(el => e.id === el.collection_id)));
		}
		setSeenList(seenList);
	};
	// const fetchPublicTags = async () => {
	// 	const publicTags = await fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/getPublicTags`)
	// 	.then(res => res.json())
	// 	.then(publicTags => {
	// 		return publicTags})
	// 	set(publicTags)
	// }
	const publicCollectionsFilter = array => {
		if (props.hideDone && props.hideMine)
			return array.filter(cn => cn.name !== authState.user.name && !seenList.some(el => cn.id === el.collection_id));
		if (props.hideDone && !props.hideMine) return array.filter(cn => !seenList.some(el => cn.id === el.collection_id));
		if (!props.hideDone && props.hideMine) return array.filter(cn => cn.name !== authState.user.name);
		return array;
	};
	const fetchPublicAuthors = async () => {
		const publicAuthors = await fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/getPublicAuthors`)
			.then(res => res.json())
			.then(publicAuthors => {
				return publicAuthors;
			});
		setPublicAuthors(
			publicAuthors
				.map(e => {
					return { value: e.name, label: e.name };
				})
				.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
		);
	};
	const removePublicStatus = async shareCode => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/removePublicStatus/${shareCode}`, {
			method: 'put',
			headers: {
				'Content-Type': 'application/json',
			},
		}).then(res => res.json());
		searchPublicCollections({
			uniqueKey: props.uniqueKey,
			userId: props.user.id,
			text: text,
			tagName: tagFilter ? tagFilter.value : null,
			authorName: authorFilter ? authorFilter.value : null,
		});

		return;
	};
	const toggleSeen = async (seen, collectionId) => {
		if (seen) {
			const seenList = await fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/removeFromSeenList/${authState.user.id}/${collectionId}`, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then(res => res.json())
				.then(publicCollections => {
					return publicCollections;
				});
			if (hideSeen)
				collectionsSort(collectionState[props.uniqueKey].collections.filter((e, i) => !seenList.some(el => e.id === el.collection_id)));
			setSeenList(seenList);
			return;
		}
		const seenList = await fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/addToSeenList/${authState.user.id}/${collectionId}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(res => res.json())
			.then(publicCollections => {
				return publicCollections;
			});
		if (hideSeen) collectionsSort(collectionState[props.uniqueKey].collections.filter((e, i) => !seenList.some(el => e.id === el.collection_id)));
		setSeenList(seenList);
	};
	const fixText = htmlText => {
		const HTMLTagRegex = /(<([^>]+)>)/gi;
		const noHTMLTagRegex = htmlText.replace(HTMLTagRegex, '');

		const ampRegex = /\&(amp)\;/gi;
		const noAmpRegex = noHTMLTagRegex.replace(ampRegex, '&');

		const lessThanRegex = /\&(lt)\;/gi;
		const noLessThanRegex = noAmpRegex.replace(lessThanRegex, '<');

		const greaterThanRegex = /\&(gt)\;/gi;
		const noGreaterThanRegex = noLessThanRegex.replace(greaterThanRegex, '>');

		const spaceRegex = /\&(nbsp)\;/gi;
		const noSpaceRegex = noGreaterThanRegex.replace(spaceRegex, '');

		const truncatedText = noSpaceRegex.length > 45 ? noSpaceRegex.slice(0, 45) + '...' : noSpaceRegex;
		return truncatedText;
	};
	const scrollRight = () => {
		const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
		const children = document.querySelector("[data-rbd-draggable-id='" + props.uniqueKey + "']");
		var panewidth = children.offsetWidth;

		var oldwidth = 425;
		var nwidth = panewidth;

		var exlude100 = children.offsetLeft - 100;
		var remaingLeft = exlude100 - panelsBlock.scrollLeft;
		var screenwidth = panelsBlock.offsetWidth - 100;
		var rightposition = screenwidth - remaingLeft;
		rightposition = rightposition - nwidth;
		var toscrollright = nwidth - rightposition;

		console.log('toscrollright2: ' + toscrollright);

		if (toscrollright > 0) {
			console.log('Should be scroll:');
			setTimeout(function () {
				panelsBlock.scroll({
					left: panelsBlock.scrollLeft + toscrollright,
					behavior: 'smooth',
				});
			}, 100);
		}
	};
	// console.log(data)
	const handleSubmit = e => {
		e.preventDefault();
		setLoading(true);
		if (view === 'public') {
			searchPublicCollections({
				uniqueKey: props.uniqueKey,
				userId: props.user.id,
				text: text,
				tagName: tagFilter ? tagFilter.value : null,
				authorName: authorFilter ? authorFilter.value : null,
			});
			return;
		} else
			searchCollectionList({
				uniqueKey: props.uniqueKey,
				userId: props.user.id,
				text: text,
				tagId: tagFilter ? tagFilter.value : null,
				categoryId: categoryFilter ? categoryFilter.value : null,
				shared: view === 'mine' ? false : true,
			});
	};

	const handlePageClick = ({ selected: selectedPage }) => {
		setCurrentPage(selectedPage);
	};
	const affiliateCheck = async () => {
		fetch(`${process.env.REACT_APP_BASE_URL}/passwordReset/findByEmail/${authState.user.email}`)
			.then(res => res.json())
			.then(userplan => {
				userplan.affliate_code ? setAffiliateCode(userplan.affliate_code) : setAffiliateCode('');
			});
	};
	const copyLinkToClipBoard = shareCode => {
		navigator.clipboard.writeText(
			`https://app.scripturenotes.com/?pane=collectionNote&shareCode=${shareCode}${affiliateCode.length > 0 ? `&via=${affiliateCode}` : ''}`
		);
		// navigator.clipboard.writeText(`https://muz3.ddns.net/?pane=collectionNote&shareCode=${shareCode}${affiliateCode.length > 0 ? `&via=${affiliateCode}` : ''}`)
		// navigator.clipboard.writeText(`http://localhost:3000/?pane=collectionNote&shareCode=${shareCode}${affiliateCode.length > 0 ? `&via=${affiliateCode}` : ''}`)
	};
	const linkCopied = () => {
		setCopyOverlay(true);
		setTimeout(() => setCopyOverlay(false), 1000);
	};

	const PER_PAGE = 15;
	const offset = currentPage * PER_PAGE;
	const currentPageData = data.slice(offset, offset + PER_PAGE).map(value => {
		const date =
			view !== 'shared'
				? new Date(Date.parse(value.created_at)).toLocaleDateString('en-US')
				: new Date(Date.parse(value.share_date)).toLocaleDateString('en-US');
		return (
			<CnTitleNDescMap key={value.id}>
				<TitleContainer>
					{view === 'shared' && (
						<LinkContainer>
							<Tooltip content='Copy share link to clipboard' direction='up' forceDirection={true}>
								<FontAwesomeIcon
									icon={faShareAlt}
									onClick={() => {
										linkCopied();
										copyLinkToClipBoard(value.share_code);
									}}
								/>
							</Tooltip>
						</LinkContainer>
					)}
					{
						view !== 'public' ? (
							<CnTitle
								onClick={e => {
									if (e.ctrlKey || e.metaKey) {
										if (value.owner_id)
											return window.open(`${process.env.REACT_APP_MAIN_URL}?pane=collectionNote&collectionId=${value.id}`, '_blank');
										return window.open(`${process.env.REACT_APP_MAIN_URL}?pane=collectionNote&collectionId=${value.id}`, '_blank');
									} else {
										!value.owner_id
											? handleAddCollection(value.id, props.uniqueKey)
											: handleAddSharedCollection(value.collection_id, props.uniqueKey, value.owner_id, authState.user.id);
									}
								}}
							>
								<DateSpan>{date}</DateSpan>
								{value.title}
								{view === 'shared' && parseInt(value.collection_saves) > 0 ? (
									<span style={{ color: 'blue' }}>
										{' '}
										{`(${value.collection_saves} ${parseInt(value.collection_saves) > 1 ? 'saves' : 'save'}) `}
									</span>
								) : (
									''
								)}
								{view === 'shared' && value.public ? <span style={{ color: 'red' }}>{`(Public)`}</span> : ''}
							</CnTitle>
						) : (
							<PublicCollection
								key={value.id}
								value={value}
								unique={unique}
								scrollRight={scrollRight}
								date={date}
								seenList={seenList.map((e, i) => e.collection_id)}
								toggleSeen={toggleSeen}
								hideSeen={hideSeen}
								uniqueKey={props.uniqueKey}
								removePublicStatus={removePublicStatus}
							/>
						)
						// <CnTitle
						// 	onClick={e => {
						// 		if (!isProUser()) return alert('This feature is for Pro users only. Please subscribe to support the development of this platform.')
						// 		if (e.ctrlKey || e.metaKey) {
						// 			if (value.owner_id) return window.open(`${process.env.REACT_APP_MAIN_URL}?pane=collectionNote&shareCode=${value.share_code}`, '_blank');
						// 			return window.open(`${process.env.REACT_APP_MAIN_URL}?pane=collectionNote&shareCode=${value.share_code}`, '_blank');
						// 		} else {
						// 			scrollRight()
						// 			dispatch({ type: 'HANDLE_ADD_PANE', payload: { key: `collectionNote_${unique()}`, type: 'collectionNote', collectionId: null, shareCode: value.share_code } });
						// 		}
						// 	}}
						// >
						// 	<DateSpan style={{ marginRight: '5px' }}>{date}</DateSpan>
						// 	{value.title}
						// 	{/* {view === 'shared' && value.public ? <span style={{ color: 'red' }}>{`(Public)`}</span> : ''} */}
						// 	<span style={{ color: 'blue' }}> {`(${value.name})`}</span>
						// 	{value.share_expires && <span style={{ color: 'red' }}> {`(${new Date(Date.parse(value.share_expires)).toLocaleDateString('en-US')})`}</span>}
						// </CnTitle>
					}
				</TitleContainer>
				{/* <CnDescription>{fixText(value.text)}</CnDescription> */}
			</CnTitleNDescMap>
		);
	});
	const pageCount = Math.ceil(data.length / PER_PAGE);
	const handleBaseFilter = option => {
		setBaseFilter(option);
		if (option.value === 'Alphabetical A-Z') {
			const alphabetical = data.sort((a, b) => (a.title < b.title ? -1 : a.title > b.title ? 1 : 0));
			setData(alphabetical);
			setCurrentPage(0);
		}
		if (option.value === 'Alphabetical Z-A') {
			const reverseAlphabetical = data.sort((a, b) => (a.title > b.title ? -1 : a.title < b.title ? 1 : 0));
			setData(reverseAlphabetical);
			setCurrentPage(0);
		}
		if (option.value === 'Created Oldest-Newest') {
			const createdAt = data.sort((a, b) => (a.created_at < b.created_at ? -1 : a.created_at > b.created_at ? 1 : 0));
			setData(createdAt);
			setCurrentPage(0);
		}
		if (option.value === 'Created Newest-Oldest') {
			const reverseCreatedAt = data.sort((a, b) => (a.created_at > b.created_at ? -1 : a.created_at < b.created_at ? 1 : 0));
			setData(reverseCreatedAt);
			setCurrentPage(0);
		}
		if (option.value === 'Modified Oldest-Newest') {
			const updatedAt = data.sort((a, b) => (a.updated_at < b.updated_at ? -1 : a.updated_at > b.updated_at ? 1 : 0));
			setData(updatedAt);
			setCurrentPage(0);
		}
		if (option.value === 'Modified Newest-Oldest') {
			const reverseUpdatedAt = data.sort((a, b) => (a.updated_at > b.updated_at ? -1 : a.updated_at < b.updated_at ? 1 : 0));
			setData(reverseUpdatedAt);
			setCurrentPage(0);
		}
	};
	const collectionsSort = collections => {
		if (baseFilter.value === 'Alphabetical A-Z') {
			const alphabetical = collections.sort((a, b) => (a.title < b.title ? -1 : a.title > b.title ? 1 : 0));
			setData(alphabetical);
			setCurrentPage(0);
		}
		if (baseFilter.value === 'Alphabetical Z-A') {
			const reverseAlphabetical = collections.sort((a, b) => (a.title > b.title ? -1 : a.title < b.title ? 1 : 0));
			setData(reverseAlphabetical);
			setCurrentPage(0);
		}
		if (baseFilter.value === 'Created Oldest-Newest') {
			const createdAt = collections.sort((a, b) => (a.created_at < b.created_at ? -1 : a.created_at > b.created_at ? 1 : 0));
			setData(createdAt);
			setCurrentPage(0);
		}
		if (baseFilter.value === 'Created Newest-Oldest') {
			const reverseCreatedAt = collections.sort((a, b) => (a.created_at > b.created_at ? -1 : a.created_at < b.created_at ? 1 : 0));
			setData(reverseCreatedAt);
			setCurrentPage(0);
		}
		if (baseFilter.value === 'Modified Oldest-Newest') {
			const updatedAt = collections.sort((a, b) => (a.updated_at < b.updated_at ? -1 : a.updated_at > b.updated_at ? 1 : 0));
			setData(updatedAt);
			setCurrentPage(0);
		}
		if (baseFilter.value === 'Modified Newest-Oldest') {
			const reverseUpdatedAt = collections.sort((a, b) => (a.updated_at > b.updated_at ? -1 : a.updated_at < b.updated_at ? 1 : 0));
			setData(reverseUpdatedAt);
			setCurrentPage(0);
		}
	};
	const handleCategoryChange = category => {
		setLoading(true);
		const tagId = tagFilter ? tagFilter.value : null;
		const categoryId = category ? category.id : null;
		if (view !== 'public') {
			searchCollectionList({
				uniqueKey: props.uniqueKey,
				userId: props.user.id,
				text: text,
				tagId,
				categoryId,
				shared: view === 'mine' ? false : true,
			});
		} else {
			const categoryId = category ? category.id : null;
			const tagId = tagFilter ? tagFilter.value : null;
			const authorName = authorFilter ? authorFilter.value : null;
			searchPublicCollections({ uniqueKey: props.uniqueKey, userId: props.user.id, text: text, tagId, categoryId, authorName });
		}
		setCategoryFilter(category);
	};
	const handleTagChange = tag => {
		setLoading(true);
		const categoryId = categoryFilter ? categoryFilter.id : null;
		const tagId = tag ? tag.tag_id : null;
		if (view !== 'public') {
			searchCollectionList({
				uniqueKey: props.uniqueKey,
				userId: props.user.id,
				text: text,
				tagId,
				categoryId,
				shared: view === 'mine' ? false : true,
			});
		} else {
			const categoryId = categoryFilter ? categoryFilter.id : null;
			const tagName = tag ? tag.value : null;
			const authorName = authorFilter ? authorFilter.value : null;
			searchPublicCollections({ uniqueKey: props.uniqueKey, userId: props.user.id, text: text, tagName, categoryId, authorName });
		}
		setTagFilter(tag);
	};
	const handleAuthorChange = author => {
		setLoading(true);
		const categoryId = categoryFilter ? categoryFilter.id : null;
		const tagName = tagFilter ? tagFilter.value : null;
		const authorName = author ? author.value : null;
		searchPublicCollections({ uniqueKey: props.uniqueKey, userId: props.user.id, text: text, tagName, categoryId, authorName });
		setAuthorFilter(author);
	};
	const toggleHideMine = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/accountSettings/toggleHideMine/${props.user.id}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				bool: !props.hideMine,
			}),
		})
			.then(res => res.json())
			.then(res => {
				props.setHideMine(!props.hideMine);
			})
			.catch(err => console.log(err));
	};
	const toggleHideDone = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/accountSettings/toggleHideDone/${props.user.id}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				bool: !props.hideDone,
			}),
		})
			.then(res => res.json())
			.then(res => {
				props.setHideDone(!props.hideDone);
			})
			.catch(err => console.log(err));
	};

	const baseOptions = [
		{ label: 'Alphabetical A-Z', value: 'Alphabetical A-Z' },
		{ label: 'Alphabetical Z-A', value: 'Alphabetical Z-A' },
		{ label: 'Created Oldest-Newest', value: 'Created Oldest-Newest' },
		{ label: 'Created Newest-Oldest', value: 'Created Newest-Oldest' },
		{ label: 'Modified Oldest-Newest', value: 'Modified Oldest-Newest' },
		{ label: 'Modified Newest-Oldest', value: 'Modified Newest-Oldest' },
	];
	return (
		<div className='CollectionList' style={{ height: '100%' }}>
			<ClosePaneWrapper>
				<Tooltip content='Close Pane' direction='up' forceDirection={true}>
					<FontAwesomeIcon
						icon={faTimes}
						onClick={() => {
							props.closePane(props.id);
							collectionDispatch({ type: 'DELETE_COLLECTION_LIST_PANE', payload: { uniqueKey: props.uniqueKey } });
						}}
					/>
				</Tooltip>
			</ClosePaneWrapper>
			<CollectionsContainer>
				<CreateCnWrapper>
					<CollectionsText {...props.dragHandleProps}>Collections</CollectionsText>
					<CreateCnBtn
						type='button'
						// disabled={!isProUser()}
						onClick={e => {
							if (!isProUser()) {
								alert('You must be a pro member to access this feature.');
								return;
							}
							if (e.ctrlKey || e.metaKey) {
								return window.open(`${process.env.REACT_APP_MAIN_URL}?pane=collectionNote&collectionId=new`, '_blank');
							} else {
								createCollection(null, props.uniqueKey);
							}
						}}
					>
						Create CN
					</CreateCnBtn>
				</CreateCnWrapper>
				<ViewButtonsContainer>
					<ViewButton
						style={{ color: view === 'mine' ? '#324599' : '#6d7886', fontWeight: view === 'mine' ? '800' : '400' }}
						onClick={() => {
							collectionsSort(collectionState[props.uniqueKey].collections);
							setLoading(true);
							setView('mine');
						}}
					>
						My Collections{' '}
					</ViewButton>
					<ViewButton> | </ViewButton>
					<ViewButton
						style={{ color: view === 'shared' ? '#324599' : '#6d7886', fontWeight: view === 'shared' ? '800' : '400' }}
						onClick={() => {
							setLoading(true);
							setView('shared');
						}}
					>
						{' '}
						Shared
					</ViewButton>
					<ViewButton> | </ViewButton>
					<ViewButton
						style={{ color: view === 'public' ? '#324599' : '#6d7886', fontWeight: view === 'public' ? '800' : '400' }}
						onClick={() => {
							setLoading(true);
							setView('public');
						}}
					>
						{' '}
						Public
					</ViewButton>
					{view === 'public' && (
						<HideCheckboxContainer>
							<input type='checkbox' value={'hide-done'} checked={props.hideDone} id='hide-done' onChange={() => toggleHideDone()} />
							<label htmlFor='hide' style={{ marginTop: 5, marginLeft: 3 }}>
								Hide Done
							</label>
						</HideCheckboxContainer>
					)}
					{view === 'public' && (
						<HideCheckboxContainer>
							<input type='checkbox' value={'hide-mine'} checked={props.hideMine} id='hide-mine' onChange={() => toggleHideMine()} />
							<label htmlFor='hide' style={{ marginTop: 5, marginLeft: 3 }}>
								Hide Mine
							</label>
						</HideCheckboxContainer>
					)}
				</ViewButtonsContainer>
				{/* SEARCH BAR */}

				<SearchBarContainer>
					<form onSubmit={handleSubmit}>
						<SearchInput
							type='text'
							placeholder='Title Search'
							name='text'
							value={text}
							onChange={e => setText(e.target.value)}
							autoComplete='off'
							autoFocus
						/>
						<SearchIconWrapper>
							<FontAwesomeIcon icon={faSearch} onClick={e => handleSubmit(e)} />
						</SearchIconWrapper>
					</form>
				</SearchBarContainer>
				{collectionState[props.uniqueKey] && (
					<div>
						<FiltersContainer>
							{/* <div className='collectionNoteForm_topRow-item'> */}
							<Select
								menuPortalTarget={document.body}
								style={{ width: '33%', menuPortal: base => ({ ...base, zIndex: 9999 }) }}
								key='baseFilter'
								className='select-category'
								placeholder='Sort By..'
								isMulti={false}
								value={baseFilter}
								options={baseOptions}
								onChange={handleBaseFilter}
								classNamePrefix='selectTag'
							/>
							{/* </div> */}
						</FiltersContainer>
						<FiltersContainer>
							{collectionState[props.uniqueKey].tags && (
								<Select
									menuPortalTarget={document.body}
									style={{ width: '33%', menuPortal: base => ({ ...base, zIndex: 9999 }) }}
									key='tags'
									className='select-tag'
									placeholder='Select Tag'
									isMulti={false}
									isClearable
									isSearchable
									isDisabled={view === 'shared'}
									value={tagFilter}
									options={collectionState[props.uniqueKey].tags.sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))}
									onChange={handleTagChange}
									classNamePrefix='selectTag'
								/>
							)}
							{view !== 'public' ? (
								collectionState[props.uniqueKey].categories && (
									<Select
										menuPortalTarget={document.body}
										style={{ width: '33%', menuPortal: base => ({ ...base, zIndex: 9999 }) }}
										key='categories'
										className='select-category'
										placeholder='Select Category'
										isMulti={false}
										isDisabled={view === 'shared'}
										isClearable
										isSearchable
										value={categoryFilter}
										options={collectionState[props.uniqueKey].categories.sort((a, b) =>
											a.label < b.label ? -1 : a.label > b.label ? 1 : 0
										)}
										onChange={handleCategoryChange}
										classNamePrefix='selectTag'
									/>
								)
							) : (
								<Select
									menuPortalTarget={document.body}
									style={{ width: '33%', menuPortal: base => ({ ...base, zIndex: 9999 }) }}
									key='authors'
									className='select-tag'
									placeholder='Select Author'
									isMulti={false}
									isClearable
									isSearchable
									// isDisabled={view !== 'mine'}
									value={authorFilter}
									options={publicAuthors}
									onChange={handleAuthorChange}
									classNamePrefix='selectTag'
								/>
							)}
						</FiltersContainer>
					</div>
				)}
			</CollectionsContainer>
			<CnDisplayContainer>
				{copyOverlay ? (
					<CopyOverlay>
						{/* <FontAwesomeIcon
								icon={faCheckCircle}
								style={{ height: '50px', width: '50px', marginBottom: '10px', backgroundColor: 'white', borderRadius: '50%' }}
							/> */}
						<span
							style={{
								// height: '50px',
								marginBottom: '10px',
								backgroundColor: 'rgb(14, 173, 58)',
								color: 'white',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: '5%',
								padding: '5px 5px',
							}}
						>
							Copied to clipboard
						</span>
					</CopyOverlay>
				) : null}
				<Scrollbars autoHide>
					<PaddingLeftNRightWrapper>
						{loading ? (
							<Loading style={{ marginTop: '300px' }} />
						) : (
							<CnTitleNDescContainer>
								{currentPageData}
								{currentPageData.length > 0 ? (
									<div>
										{data && data.length >= 16 ? (
											<ReactPaginate
												previousLabel={'Prev'}
												nextLabel={'Next'}
												pageCount={pageCount}
												onPageChange={handlePageClick}
												containerClassName={'pagination'}
												previousLinkClassName={'pagination__link'}
												nextLinkClassName={'pagination__link'}
												disabledClassName={'pagination__link--disabled'}
												activeClassName={'pagination__link--active'}
												marginPagesDisplayed={1}
												pageRangeDisplayed={2}
											/>
										) : null}
									</div>
								) : (
									<p>No collections found</p>
								)}
							</CnTitleNDescContainer>
						)}
					</PaddingLeftNRightWrapper>
				</Scrollbars>
			</CnDisplayContainer>
		</div>
	);
};

export default CollectionsList;
